// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")

import 'style/admin_ui'
import "@fortawesome/fontawesome-free/js/all"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

document.addEventListener('turbolinks:load', () => {
  // Header user & community selects
  const communitySelect = document.getElementById('community_select');
  const userSelect = document.getElementById('user_select');
  const filtersForm = document.getElementById('filters_form');

  // "Nos priorités" 2nd Header user & community selects
  const avgCommunitySelect = document.getElementById('avg_community_select');
  const avgUserSelect = document.getElementById('avg_user_select');
  const avgFiltersForm = document.getElementById('avg_filters_form');

  // "Nos priorités" 1st table filters
  const tableForm = document.getElementById('table_form');
  const performanceField = document.getElementById('performance_field');
  const themeField = document.getElementById('theme_field');
  const criteriumField = document.getElementById('library_criterium_field');
  const searchField = document.getElementById('search_field');

  // "Nos priorités" 2nd table filters
  const avgTableForm = document.getElementById('avg_table_form');
  const avgPerformanceField = document.getElementById('avg_peformance_field');
  const avgThemeField = document.getElementById('avg_theme_field');
  const avgCriteriumField = document.getElementById('avg_library_criterium_field');
  const avgSearchField = document.getElementById('avg_search_field');


  // Header user & community selects
  communitySelect?.addEventListener('change', async () => {
    filtersForm.submit();
  });

  userSelect?.addEventListener('change', async () => {
    filtersForm.submit();
  });


  // "Nos priorités" 2nd Header user & community selects
  avgCommunitySelect?.addEventListener('change', async () => {
    avgFiltersForm.submit();
  });

  avgUserSelect?.addEventListener('change', async () => {
    avgFiltersForm.submit();
  });


  // "Nos priorités" 2nd table filters
  avgPerformanceField?.addEventListener('change', async () => {
    avgTableForm.submit();
  });

  avgThemeField?.addEventListener('change', async () => {
    avgTableForm.submit();
  });

  avgCriteriumField?.addEventListener('change', async () => {
    avgTableForm.submit();
  });

  avgSearchField?.addEventListener('change', async () => {
    avgTableForm.submit();
  });


  // "Nos priorités" 1st table filters
  performanceField?.addEventListener('change', async () => {
    tableForm.submit();
  });

  themeField?.addEventListener('change', async () => {
    tableForm.submit();
  });

  criteriumField?.addEventListener('change', async () => {
    tableForm.submit();
  });

  searchField?.addEventListener('change', async () => {
    tableForm.submit();
  });
});

